export const SERVER_URL = 'https://campinglive.kr';
export const DEV_LOCAL_API_SERVER_URL = 'http://localhost:8080/v1';
export const SERVICE_API_SERVER_URL = 'https://api.campinglog.kr/v1';

// export const API_URL = `${SERVER_URL}/api`;
export const API_URL = `${SERVICE_API_SERVER_URL}`;
export const PREFIX_ROUTE = '/map'


export const DAUMAPIKEY = 'cae373c9f88d6cec85ce81e007987dab';
export const KAKAO_ADDRESS = 'https://dapi.kakao.com/v2/local/search/address.json';  // 주소로 정보얻기
export const KAKAO_GEO_ADDRESS = 'https://dapi.kakao.com/v2/local/geo/coord2address.json';  // 좌표로 주소로 정보얻기
