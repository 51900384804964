import React from 'react';
// @ts-ignore
import {Marker} from 'react-naver-maps';

export interface markerParams {
  key: number | string,
  position: any,
  data: any,
  markerType: string,
  onClick: (any: any) => void,
}

const PublicMarkers = (props: markerParams) => {
  const {position, onClick, data, markerType } = props;
  const zIndex = 0;
  const animation = 0;

  const htmlMarker = () => {
    if(markerType === 'CLUSTER') {
      const { title, cnt } = data.data

      return {
        content: [
          `<div class="publicClusterMarker">`,
          `<div class="titleText01">${title}</div>`,
          `<div class="titleText02">${cnt}개</div>`,
          `</div>`,
        ].join(''),
      }
    }else{
      const { facltNm } = data.data
      const widthSize = facltNm && facltNm.length
      const wSize = (widthSize*11)+5
      return {
        content: [
          `<div class="publicMarker" style="width:${wSize}px; left:${-wSize/2 -15}px;">`,
          `<div class="titleText01">${facltNm}</div>`,
          `</div>`,
        ].join(''),
      }
    }
  }

  return (
    <Marker
      zIndex={zIndex}
      animation={animation}
      position={position}
      title={''}
      // markerCount={markerCount}
      icon={htmlMarker()}
      // onClick={markerType === 'UNIT' ? onClick : null}
      onClick={onClick}
      // events={['mouseover', 'mouseout', 'click']}
    />
  )

}

export default PublicMarkers
