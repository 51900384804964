import React from 'react';
import {Marker} from 'react-naver-maps';
import '../styles/css/marker.scss';

export interface markerParams {
  key: number | string,
  position: any,
  data: any,
  zoom: number,
  onClick: (id: number | string) => void,
}

const Markers = (props: markerParams) => {
  const {position, onClick, data, zoom} = props;
  const zIndex = 0;
  const animation = 0;
  // const cssName = data.publicType === 'F' ? 'friendRound' : 'round1';

  const getMarkerClassName = (type: string) => {
    switch (type){
      case 'F' :
        return 'friendRound'
      case 'H' :
        return 'hiddenRound'
      default:
        return 'publicRound'
    }
  }

  const htmlMarker = () => {
    // console.log(zoom)
    // if(data.photo){
    //   return {
    //     content: [
    //       `<img src="https://chapak.kr/static/photo/user/map/${data.photo}" class="imageRound" alt="image"/>`,
    //     ].join(''),
    //   }
    // }

    if(zoom > 12) {
      return {
        content: [
          `<div class="markerRound1">`,
          `<div class="roundDefault ${getMarkerClassName(data.publicType)}" />`,
          `</div>`,
        ].join(''),
      }
    }else{
      return {
        content: [
          `<div class="markerRound2">`,
          `<div class="roundDefault ${getMarkerClassName(data.publicType)}" />`,
          `</div>`,
        ].join(''),
      }
    }
  }

  return (
    <Marker
      zIndex={zIndex}
      animation={animation}
      position={position}
      title={''}
      // markerCount={markerCount}
      icon={htmlMarker()}
      onClick={onClick}
      // events={['mouseover', 'mouseout', 'click']}
    />
  )
}

export default Markers
