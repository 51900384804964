import axios from "axios";
import * as Config from "./config";
import * as Utils from "./utils";

type MethodType =
  | 'get' | 'GET'
  | 'delete' | 'DELETE'
  | 'options' | 'OPTIONS'
  | 'post' | 'POST'
  | 'put' | 'PUT'


interface FetchParams {
  serverType: string,
  url: string,
  type: MethodType,
  headers?: any,
  params?: any,
  data?: any
}

const fetchData = (fetchParams: FetchParams): any => {
  let urlType: string;
  if (fetchParams.serverType === 'P') {
    urlType = Config.API_URL;
  } else {
    urlType = Config.API_URL;
  }
  // @ts-ignore
  return axios({
    method: fetchParams.type,
    baseURL: urlType,
    url: fetchParams.url,
    headers: fetchParams.headers,
    params: fetchParams.params,
    data: fetchParams.data,
  })
};

// 일반전인  Axios 는 Header 에 토큰을 담아서 보내는데 Refresh 는
// 별도로 보내야 함 Refresh 를 요청할때 기본 요청으로 보내면 새로 받은 토큰을 셋팅하지 못함
const reFreshInstance = axios.create()
const reFreshFetch = (fetchParams : FetchParams) : any =>  {
  let urlType : string;
  if (fetchParams.serverType === 'P') {
    urlType = Config.API_URL;
  }else {
    urlType = Config.API_URL;
  }

  const config = {
    method: fetchParams.type,
    baseURL: urlType,
    url: fetchParams.url,
    headers: fetchParams.headers,
    params: fetchParams.params,
    data: fetchParams.data,
  }

  return reFreshInstance(config)
};

// 카카오 헤더는는 카카오 토큰을 보내야함
const kakaoInstance = axios.create()
const kakaoFetch = (fetchParams : FetchParams) : any =>  {
  let urlType : string;
  if (fetchParams.serverType === 'P') {
    urlType = Config.API_URL;
  }else {
    urlType = Config.API_URL;
  }

  const config = {
    method: fetchParams.type,
    baseURL: urlType,
    url: fetchParams.url,
    headers: fetchParams.headers,
    params: fetchParams.params,
    data: fetchParams.data,
  }

  return kakaoInstance(config)
};
/**
 *  //////////////////////////////////////////////////////////////////////
 *  API START ============================================================
 *  //////////////////////////////////////////////////////////////////////
 */

export interface CampParams {
  lat: string,
  lng: string,
  pnuCode?: string,
  sidoCode?: string,
  sggCode?: string,
  campName?: string,
  jibunAddress: string,
  roadAddress?: string,
  sidoName?: string,
  sggName?: string,
  dongName?: string,
  riName?: string,
  zipCode?: string,
  toilet?: boolean,
  campMemo?: string,
}

export class CampDAO implements CampParams {
  lat: string = '';
  lng: string = '';
  pnuCode?: string = '';
  sidoCode?: string = '';
  sggCode?: string = '';
  campName?: string = '캠핑장명칭';
  jibunAddress: string = '';
  roadAddress?: string = '';
  sidoName?: string = '';
  sggName?: string = '';
  dongName?: string = '';
  riName?: string = '';
  zipCode?: string = '';
  toilet: boolean = false;
  campMemo?: string = '';
}

// ReFresh Token
export const refreshTokenData_API = (reFreshToken: string) : any => {
  const url = `/auth_api/refresh`;
  const tempHeaders = {
    Authorization: reFreshToken ? `Bearer ${reFreshToken}` : "",
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  };

  return reFreshFetch( {
    serverType: 'P',
    url: url,
    type: 'POST',
    headers: tempHeaders,
    params: '',
    data: '',
  })
}


export const addCamp_API = ( cp : CampParams ): any => {
  let bodyFormData = new FormData();
  bodyFormData.append('lat', cp.lat);
  bodyFormData.append('lng', cp.lng);
  bodyFormData.append('pnuCode', cp.pnuCode!);
  bodyFormData.append('sidoCode', cp.sidoCode!);
  bodyFormData.append('sggCode', cp.sggCode!);
  bodyFormData.append('campName', cp.campName!);
  bodyFormData.append('jibunAddress', cp.jibunAddress);
  bodyFormData.append('roadAddress', cp.roadAddress!);
  bodyFormData.append('sidoName', cp.sidoName!);
  bodyFormData.append('sggName', cp.sggName!);
  bodyFormData.append('dongName', cp.dongName!);
  bodyFormData.append('riName', cp.riName!);
  bodyFormData.append('zipCode', cp.zipCode!);
  bodyFormData.append('toilet', cp.toilet === true ? 'true' : 'false');
  bodyFormData.append('memo', cp.campMemo!);

  const url = `/camp_api/camp`;
  const tempHeaders = {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  };
  const tempParams = {};
  return fetchData({
    serverType: 'P',
    url: url,
    type: 'POST',
    headers: tempHeaders,
    params: tempParams,
    data: bodyFormData,
  })
}

export const getCampMarker_API = (map:any,  zoom: number, type?: string): any => {
  const {bounds} = map;
  const url = `/place/marker`;
  const tempParams = {
    neLat: bounds._ne._lat,
    neLng: bounds._ne._lng,
    swLat: bounds._sw._lat,
    swLng: bounds._sw._lng,
    zoom: zoom,
    reqService: type,
  };
  return fetchData({
    serverType: 'P',
    url: url,
    type: 'GET',
    headers: {},
    params: tempParams,
    data: '',
  })
}


// export const getMyCampMarker_API = (map:any,  zoom: number, type?: string): any => {
//   const {bounds} = map;
//   const url = `/camp_api/myCampMarker`;
//   const tempHeaders = {
//     Accept: 'application/json',
//     'Content-Type': 'multipart/form-data'
//   };
//   const tempParams = {
//     neLat: bounds._ne._lat,
//     neLng: bounds._ne._lng,
//     swLat: bounds._sw._lat,
//     swLng: bounds._sw._lng,
//     zoom: zoom,
//     reqService: type,
//   };
//   return fetchData({
//     serverType: 'P',
//     url: url,
//     type: 'GET',
//     headers: tempHeaders,
//     params: tempParams,
//     data: '',
//   })
// }


export const getAddressDataFromKakao_API = (e: any) => {
  const url = Config.KAKAO_ADDRESS;
  const tempHeaders = {
    Authorization: `KakaoAK ${Config.DAUMAPIKEY}`,
    Accept: 'application/json',
  };
  const tempParams = {
    query: e.address,
  };
  return kakaoFetch({
    serverType: 'P',
    url: url,
    type: 'GET',
    headers: tempHeaders,
    params: tempParams,
    data: '',
  })
};



/**
 *  //////////////////////////////////////////////////////////////////////
 *  Public Data API  =====================================================
 *  //////////////////////////////////////////////////////////////////////
 */
export const getPublicCampMarker_API = (map:any,  zoom: number, type?: string): any => {
  const {bounds} = map;
  const url = `/public/camp/marker`
  const tempHeaders = {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  };
  const tempParams = {
    neLat: bounds._ne._lat,
    neLng: bounds._ne._lng,
    swLat: bounds._sw._lat,
    swLng: bounds._sw._lng,
    zoom: zoom,
    reqService: type,
  };

  return fetchData({
    serverType: 'P',
    url: url,
    type: 'GET',
    headers: tempHeaders,
    params: tempParams,
    data: '',
  })
}














// setToke Header
axios.interceptors.request.use(
  async config => {
    const token = await Utils.getAccessToken()
    if (token) {
      config.headers.Authorization = "Bearer "+token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
);

// refresh token
axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response === undefined) {
      return Promise.reject(error);
    }

    const {data, status, config} = error.response;
    let originalRequest = config;
    // const ExpiredResult = {
    //   status: false,
    //   data: '',
    // }

    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (data.data === 'Expired' && !data.status) {
        console.log('refresh token request start')
        const reFreshToken = await Utils.getRefreshToken();
        if(!reFreshToken){
          return Promise.reject(error);
        }

        return refreshTokenData_API(reFreshToken)
          .then( (res:any) => {
            console.log('new access token setting')
            // console.log(res)
            const {data, status} = res.data;
            const {access_token} = data;
            // 토큰의 사용자가 유효하지 않은 때
            if(!status){
              // Utils.clearToken()
              return Promise.reject(error);
            }
            Utils.setAccess_token(access_token);
            originalRequest.headers['Authorization'] = 'Bearer ' + access_token;
            return axios(originalRequest);
          })
          .catch((error:any) => {
            // Utils.clearToken()
            return Promise.reject(error);
          })
      }
      // else if (data === 'rtf_Expired' && !status) {
      //   // console.log('refresh_token 만료된 경우 처리')
      //   return ExpiredResult;
      // } else if (data === 'rft_Error' && !status) { // rft_Error
      //   console.log('기타 에러처')
      //   return ExpiredResult;
      // }
    }

    return Promise.reject(error.response);
  }
)

