import React from 'react';
import '../styles/css/intro.scss';
import {SERVER_URL} from "../helper/config";


const InTro = () => {
    return (
        <div className={'introWrapper'}>
            <div className={'logoBox'}>
                <div className={'logoImg'}/>
            </div>

            {/*<span className={'text01'}>캠핑라이브</span>*/}
            {/*<span className={'text02'}> 곧 찾아뵙겠습니다.</span>*/}
            <div className={'sec'}>
                <div className={'text01'}>캠핑로그</div>
                <div className={'text02'}>나만의 캠핑맵</div>
                <div className={'text02'}>좋았던 캠핑 경험과 장소를 기록하고</div>
                <div className={'text02'}>지인과 함께 공유해보세요</div>
            </div>

            <div className={'sec downloadBox'}>
                <div className={'downText'}>
                    <a href={`https://apps.apple.com/kr/app/%EC%BA%A0%ED%95%91%EB%9D%BC%EC%9D%B4%EB%B8%8C-%EB%82%98%EB%A7%8C%EC%9D%98-%EC%BA%A0%ED%95%91%EB%A7%B5/id1566993756`} target={'_blank'}>애플스토어</a>
                </div>
                <div className={'downText'}>
                    <a href={`https://play.google.com/store/apps/details?id=com.carcampapp`}>플레이스토어</a>
                </div>
            </div>

            <div className={'footerInfoWrapper'}>
                <div className={'footerTextBox'}>
                    <div className={'footerText01'}>회사명 : 엔유컴즈</div>
                    <div className={'footerText01'}>사업자등록번호 : 123-38-20296 | 통신판매 : 2019-서울서초-2270</div>
                    <div className={'footerText01'}>주소 : 주소: 서울특별시 서초구 강남대로 369 12층 (우:06621)</div>
                    <div className={'footerText01'}>문의 : 1644-0327</div>
                </div>
                <div className={'footerLinkBox'}>
                    <div className={'footerText01'}>
                        <a href={`${SERVER_URL}/notice/provision`}>이용약관</a>
                    </div>
                    <div className={'footerText01'}>|</div>
                    <div className={'footerText01'}>
                        <a href={`${SERVER_URL}/notice/privacy`}>개인정보보호방침</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

InTro.propTypes = {};

export default InTro;
