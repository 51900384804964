import React, {useState} from 'react'
import {PulseLoader} from "react-spinners";
import {FaGlobeAsia, FaMinus, FaPlus} from "react-icons/fa";
import {ImBasecamp} from "react-icons/im";
import {FiEye, FiEyeOff} from "react-icons/fi";
import { GiCampingTent } from "react-icons/gi";
import {MdGpsFixed} from "react-icons/md";
import {isBrowser} from "react-device-detect";
import '../styles/css/map.scss';
import {DiaryType} from "../pages/MapPage";

interface MapControlParams {
  isAllMarkerShow: boolean,
  isMyLocation: boolean,
  isMyLocationLoaded: boolean,
  isCadastralFullLayer: boolean,
  isTotalMarkerShow: boolean,
  isPublicMarker: boolean,
  isSatellite: boolean,
  isMyMarker: boolean,

  diaryType: DiaryType,

  funcClickPlus(): void,

  funcClickMinus(): void,

  funcClickMyLocation(): void,

  funcToggleCadastral(): void,

  funcToggleMyCamp(): void,

  funcToggleSatelliteLayer(): void,

  funcToggleMarkerShow(): void,

  funcToggleContentsShow(): void,

  funcTogglePublicMarkerShow(): void,

  funcSetDiaryType(p: { code: string; name: string }): void,
}

// type DefaultProps = Readonly<typeof defaultProps>
// const defaultProps = {
//   isAllMarkerShow: false,
// }

function MapControlView(props: MapControlParams): any {
  const {
    isAllMarkerShow, isMyLocation, isMyLocationLoaded, isSatellite, isPublicMarker, diaryType,
    funcClickPlus, funcClickMinus, funcClickMyLocation, funcToggleSatelliteLayer,
    funcToggleMarkerShow, funcTogglePublicMarkerShow, funcSetDiaryType,
    isMyMarker, funcToggleContentsShow, isCadastralFullLayer, isTotalMarkerShow, funcToggleCadastral, funcToggleMyCamp
  } = props;

  const [isDiaryBox, setIsDiaryBox] = useState(false)
  // const [diaryType, setDiaryType] = useState({code:'AL',name:'전체'})


  const changeDiaryType = (type: string): void => {
    console.log('changeDiaryType', type)

    switch (type) {
      case 'MY':
        funcSetDiaryType({code: 'MY', name: '마이'})
        break
      case 'FR':
        funcSetDiaryType({code: 'FR', name: '친구'})
        break
      default:
        funcSetDiaryType({code: 'AL', name: '전체'})
        break
    }

    setIsDiaryBox(false)
  }

  return (
    <div className='MapControlView'>
      <div className={`Button MyLocationControl iconVCenter ${isMyLocation ? ' ActiveButton' : ''}`}
           onClick={funcClickMyLocation}>
        <MdGpsFixed size={18}/>
        <div className='loader'>
          <PulseLoader
            size={6}
            margin={'1px'}
            color={'#30a579'}
            loading={isMyLocationLoaded}
          />
        </div>
        {isBrowser ? <span className='ButtonInfo buttonSize3'>현위치</span> : ''}
      </div>
      <div className='Button PlusControl iconVCenter' onClick={funcClickPlus}>
        <FaPlus size={14}/>
        {isBrowser ? <span className='ButtonInfo buttonSize2'>확대</span> : ''}
      </div>
      <div className='Button MinusControl iconVCenter' onClick={funcClickMinus}>
        <FaMinus size={14}/>
        {isBrowser ? <span className='ButtonInfo buttonSize2'>축소</span> : ''}
      </div>
      {/*<div className={`Button LayerControl LayerControl1 iconVCenter ${isCadastralFullLayer ? ' ActiveButton' : ''}`} onClick={funcToggleCadastral}>*/}
      {/*  <FaLayerGroup size={16}/>*/}
      {/*  { isBrowser ? <span className='ButtonInfo buttonSize3'>지적도</span> : ''}*/}
      {/*</div>*/}

      {
        isBrowser ?
          <div
            className={`Button LayerControl LayerControl1 iconVCenter ${diaryType.name !== '전체' ? 'ActiveButton' : ''}`}
            onClick={() => setIsDiaryBox(!isDiaryBox)}>
            <ImBasecamp size={17}/>
            <span className='ButtonInfo buttonSize6'>캠핑다이어리</span>
          </div>
          :
          <div
            className={`Button LayerControl LayerControl1 iconVCenter ${diaryType.name !== '전체' ? ' ActiveButton' : ''}`}
            onClick={() => setIsDiaryBox(!isDiaryBox)}>
            <span className={'font13 fontBold'}>{diaryType.name}</span>
          </div>
      }
      {
        isDiaryBox &&
        <div className={'diaryOptionBox'}>
            <div className={`Button LayerControl LayerControl2 iconVCenter`} onClick={() => changeDiaryType('AL')}>
                <span className={'font13 fontBold'}>전체</span>
            </div>
            <div className={`Button LayerControl LayerControl2 iconVCenter`} onClick={() => changeDiaryType('MY')}>
                <span className={'font13 fontBold'}>마이</span>
            </div>
            <div className={`Button LayerControl LayerControl2 iconVCenter`} onClick={() => changeDiaryType('FR')}>
                <span className={'font13 fontBold'}>친구</span>
            </div>
        </div>
      }
      {
        isBrowser ?
          <div className={`Button LayerControl LayerControl2 iconVCenter ${isPublicMarker ? ' ActiveButton' : ''}`}
               onClick={funcTogglePublicMarkerShow}>
            <GiCampingTent size={20}/>
            <span className='ButtonInfo buttonSize5'>캠핑장목록</span>
          </div>
          :
          <div className={`Button LayerControl LayerControl2 iconVCenter ${isPublicMarker ? ' ActiveButton' : ''}`}
               onClick={funcTogglePublicMarkerShow}>
            <span className={'font13 fontBold'}>캠핑</span>
          </div>
      }


      {/*{*/}
      {/*  isBrowser ?*/}
      {/*    <div className={`Button LayerControl LayerControl1 iconVCenter ${isMyMarker ? ' ActiveButton' : ''}`}*/}
      {/*         onClick={funcToggleMyCamp}>*/}
      {/*      <ImBasecamp size={16}/>*/}
      {/*      <span className='ButtonInfo buttonSize5'>나의캠핑장</span>*/}
      {/*    </div>*/}
      {/*    :*/}
      {/*    <div className={`Button LayerControl LayerControl1 iconVCenter ${isMyMarker ? ' ActiveButton' : ''}`}*/}
      {/*         onClick={funcToggleMyCamp}>*/}
      {/*      <span className={'font13 fontBold'}>마이</span>*/}
      {/*    </div>*/}
      {/*}*/}


      {
        isBrowser ?
          <div className={`Button LayerControl LayerControl1 iconVCenter ${isSatellite ? ' ActiveButton' : ''}`}
               onClick={funcToggleSatelliteLayer}>
            <FaGlobeAsia size={16}/>
            <span className='ButtonInfo buttonSize3'>위성도</span>
          </div>
          :
          <div className={`Button LayerControl LayerControl1 iconVCenter ${isSatellite ? ' ActiveButton' : ''}`}
               onClick={funcToggleSatelliteLayer}>
            <span className={'font13 fontBold'}>위성</span>
          </div>
      }
      {
        isBrowser ?
          <div className={`Button MarkerShowControl iconVCenter ${!isAllMarkerShow ? ' ActiveButton' : ''} `}
               onClick={funcToggleMarkerShow}>
            {isAllMarkerShow
              ? <FiEye size={18}/>
              : <FiEyeOff size={18}/>
            }
            <span className='ButtonInfo buttonSize2'>숨김</span>
          </div>
          :
          <div className={`Button MarkerShowControl iconVCenter ${!isAllMarkerShow ? ' ActiveButton' : ''} `}
               onClick={funcToggleMarkerShow}>
            <span className={'font13 fontBold'}>숨김</span>
          </div>
      }
      {/*<div className={`Button LayerControl LayerControl1 iconVCenter ${isMyMarker ? ' ActiveButton' : ''}`} onClick={funcToggleMyCamp}>*/}
      {/*  <ImBasecamp size={16}/>*/}
      {/*  { isBrowser ? <span className='ButtonInfo buttonSize5'>나의캠핑장</span> : ''}*/}
      {/*</div>*/}
      {/*<div className={`Button LayerControl LayerControl1 iconVCenter ${isSatellite ? ' ActiveButton' : ''}`} onClick={funcToggleSatelliteLayer}>*/}
      {/*  <FaGlobeAsia size={16}/>*/}
      {/*  { isBrowser ? <span className='ButtonInfo buttonSize3'>위성도</span> : ''}*/}
      {/*</div>*/}
      {/*<div className={`Button MarkerShowControl iconVCenter ${!isAllMarkerShow ? ' ActiveButton' : ''} `} onClick={funcToggleMarkerShow}>*/}
      {/*  { isAllMarkerShow*/}
      {/*    ? <FiEye size={18}/>*/}
      {/*    : <FiEyeOff size={18}/>*/}
      {/*  }*/}
      {/*  { isBrowser ? <span className='ButtonInfo buttonSize2'>숨김</span> : ''}*/}
      {/*</div>*/}
      {/*<div className={`Button ContentsShowControl iconVCenter ${isTotalMarkerShow ? '' : 'ActiveButton'}`} onClick={funcToggleContentsShow}>*/}
      {/*  { isTotalMarkerShow ? '빌딩' : '공실' }*/}
      {/*</div>*/}
    </div>
  )
}

export default MapControlView
